import * as React from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";

export default function ResponsiveList({ categories, type }) {
  let content = "";

  if (categories) {
    let gridmd = 12;
    if (type !== 1) {
      gridmd = 12 / categories.length;
    }

    content = categories.map((category) => (
      <Grid item xs={12} sm={6} md={gridmd} key={category.id}>
        <Card
          sx={{
            height: "100%",
            bgcolor: category.bgColor,
            color: "white",
            boxShadow: 15,
          }}
        >
          <CardContent>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                textAlign: "center",
                height: "60px",
                color: "white",
              }}
              component="div"
            >
              {category.title}
            </Typography>
            {category.subTitle ? (
              <Grid container spacing={0}>
                {category.subTitle.map((subTitle) => (
                  <React.Fragment key={subTitle.title}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography
                        variant="body2"
                        sx={{
                          marginLeft: "10px",
                          textAlign: "left",
                          fontSize: "18px",
                          color: "white",
                          fontWeight: 700,
                        }}
                        color="text.secondary"
                      >
                        {subTitle.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 700,
                          textAlign: "center",
                          fontSize: "18px",
                          color: "white",
                        }}
                        color="text.secondary"
                      >
                        {subTitle.value}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            ) : (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                    textAlign: "center",
                    mt: category.fsize ? 2 : "auto",
                    fontSize: category.fsize ? category.fsize : "50px",
                    color: "white",
                  }}
                  color="text.secondary"
                >
                  {category.value}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                    textAlign: "center",
                    mt: category.fsize ? "32px" : "20px",
                    fontSize: "15px",
                    color: "white",
                  }}
                  color="text.secondary"
                >
                  Today
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    ));
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {content}
      </Grid>
    </Box>
  );
}
