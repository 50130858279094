import React, { useEffect, useState } from "react";
import { useDeleteBorrowerMutation } from "../../../app/api/users/usersApiSlice";
import { Paper, Typography } from "@mui/material";
import CustomTable from "../../../components/tables/Table";
import DottedButton from "../../../components/buttons/DottedButtons.js";
import { CSVDownload } from "react-csv";
import dayjs from "dayjs";
import SharedDialog from "../../../components/modals/SharedDialog.js";
import { CustomSnackbar } from "../../../components/popover/Snackbar";
import socketService from "../../../app/api/ws/initializeWebSocketService.js";
import { useGetBorrowersListQuery } from "../../../app/api/users/borrowers.js";
import JSHTable from "../../../components/JSHub/JSHTable.js";
function BorrowersList() {
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogChoice, setDialogChoice] = useState("");
  const [dialogMsg, setDialogMsg] = useState("");
  const [borrowerId, setBorrowerId] = useState("");
  const [status, setStatus] = useState("");

  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [snackBarColor, setSnackBarColor] = React.useState("");
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const [borrowerData, setBorrowerData] = useState([]);
  const { data: borrowers, isLoading: isBorrowersLoading } =
    useGetBorrowersListQuery(
      {
        page: page + 1,
        rowsPerPage,
        search: searchValue,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  let columns = [];

  columns = [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "address",
      label: "Address",
    },
    {
      id: "contact_number",
      label: "Contact Number",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "membersgroup",
      label: "Members Group",
    },
    {
      id: "status",
      label: "Borrower Status",
    },
    {
      id: "actions",
      label: "Actions",
    },
  ];
  const options = {
    allowEdit: true,
    allowDelete: true,
    allowView: true,
    allowAdd: true,
    allowImport: true,
    allowExport: true,
    allowSearch: true,
    numberPerPage: 10,
    selectable: false,
    page: setPage,
    totalPageNumber: 0,
    tableTitle: "",
    isLoading: isLoading,
    setSearchValue: setSearchValue,
    searchValue: searchValue,
    setIsLoading: setIsLoading,
    setRowsPerPage: setRowsPerPage,
  };

  useEffect(() => {
    if (borrowers && !isBorrowersLoading) {
      const borrowerList = borrowers.borrowers.map((borrower) => {
        return {
          id: borrower.id,
          name: borrower.name,
          address: borrower.address,
          email: borrower.email,
          status: borrower.status === 1 ? "Inactive" : "Active",
          contact_number: borrower.phone,
          membersgroup: borrower.membersgroup,
        };
      });
      setTotalPageNumber(borrowers.total[0]?.total);
      setBorrowerData(borrowerList);
    }
  }, [borrowers, isBorrowersLoading]);

  const [deleteBorrower] = useDeleteBorrowerMutation();

  const handleCloseSnackbar = () => {
    setPopoverOpen(false);
  };

  useEffect(() => {
    const deleteBorrowerAsync = async () => {
      if (dialogChoice === 1) {
        try {
          let res = await deleteBorrower({
            id: borrowerId,
            status: status,
            type: "borrower",
          }).unwrap();
          if (res) {
            socketService.sendMessage({ type: "refresh" });
            setDialogChoice("");
            setSnackBarMsg("Successfully set borrower to inactive");
            setSnackBarColor("success");
            setPopoverOpen(true);
          }
          // Handle success (e.g., show notification, refresh list)
        } catch (error) {
          setSnackBarMsg("Failed to set borrower to inactive");
          setSnackBarColor("error");
          setPopoverOpen(true);
          // Handle error (e.g., show error message)
        }
      }
    };

    deleteBorrowerAsync();
  }, [dialogChoice, borrowerId, deleteBorrower, status]);

  options.totalPageNumber = totalPageNumber;

  const btnOptions = ["View", "Edit", "Delete"];
  const customBtn = (id, data, computed_data) => {
    const statusButton = data.status === "Inactive" ? "Activate" : "Deactivate";
    const updatedBtnOptions = [...btnOptions.slice(0, 2), statusButton];
    return (
      <DottedButton
        options={updatedBtnOptions}
        //data={loanInfoData[dataIndex]}
        borrower_id={data.id} // Adding borrower_id here
        isDialogOpen={setIsDialogOpen}
        dialogMsg={setDialogMsg}
        uid={data.id}
        id={data.id}
        loanStatus={data.loan_status}
        setUserId={setBorrowerId}
        type={"view"}
        status={setStatus}
      />
    );
  };

  return (
    <Paper elevation={3} style={{ padding: "20px" }}>
      <JSHTable
        headers={columns}
        data={borrowerData}
        //title={title}
        options={options}
        actions={customBtn}
      />
      {popoverOpen && (
        <CustomSnackbar
          openSnackBar={popoverOpen}
          closeSnackBar={handleCloseSnackbar}
          messageData={snackBarMsg}
          colorVariant={snackBarColor}
        />
      )}
      {isDialogOpen && (
        <SharedDialog
          isOpen={isDialogOpen}
          isDialogOpen={setIsDialogOpen}
          title={"Borrower"}
          bodyContent={dialogMsg}
          setDialogChoice={setDialogChoice}
        />
      )}
    </Paper>
  );
}

export default BorrowersList;
