import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { MemberModal } from "../modals/MemberModals";
import WithdrawData from "../../features/members/withdraw/WithdrawData";
import LoanEdit from "../../features/loans/loanForms/LoanEdit";
import PaymentFormv1 from "../../features/loans/paymentForm/PaymentFormv1";
import ReloanData from "../../features/members/reloanForm/ReloanData";

const ITEM_HEIGHT = 48;

export default function LongMenu(data) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const options = data.options;
  const navigate = useNavigate(); // Hook for navigation

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenu = (event) => {
    console.log(event.currentTarget);
    const btnString = event.currentTarget.textContent;
    const user_id = event.currentTarget.id;
    switch (btnString) {
      case "View":
        navigate(`/members/loans/${user_id}`); // Navigate to profile page
        break;
      case "Edit":
        navigate(`/members/edit/${user_id}`); // Navigate to edit page
        break;
      case "Edit Loan":
        data.setmodalType("EditLoan");
        data.setmodalValue(data.data);
        data.setmodalTitle("Edit Loan");
        console.log(data.indexValue);
        data.setModalOpen(true); // Open the modal
        handleClose();
        break;
      case "Less Payment":
        data.setmodalType("Add Payment");
        data.setmodalTitle("Less Payment");
        data.setProfileName(data.uid);
        data.setmodalValue(data.data);
        data.setModalOpen(true); // Open the modal

        handleClose();
        break;
      case "Reloan":
        data.setmodalType("Reloan");
        data.setmodalTitle("Reloan Application");
        data.setProfileName(data.uid);
        data.setmodalValue(data.data);
        data.setModalOpen(true); // Open the modal
        handleClose();
        break;
      case "Deactivate":
      case "Delete":
        data.dialogMsg(`Are you sure you want to ${btnString} this user?`);
        data.isDialogOpen(true);
        data.setUserId(data.id);
        data.status(1);
        handleClose();
        break;
      case "Activate":
        data.dialogMsg(`Are you sure you want to ${btnString} this user?`);
        data.status(0);
        data.isDialogOpen(true);
        data.setUserId(data.id);
        handleClose();
        break;
      case "Approve":
        data.isDialogOpen(true);
        data.setUserId(data.loanId);
        data.dialogMsg("Are you sure you want to approve this loan?");
        data.setEffectiveDate(data.effectiveDate);
        data.status(1);
        handleClose();
        break;
      case "Reject":
        data.isDialogOpen(true);
        data.dialogMsg("Are you sure you want to reject this loan?");
        data.setUserId(data.loanId);
        data.status(0);
        handleClose();
        break;
      case "Withdraw":
        data.setmodalType("Withdraw");
        data.setmodalTitle("withdraw");
        data.setmodalValue(data.data);
        data.setModalOpen(true); // Open the modal
        handleClose();
        break;
      default:
        break;
    }
  };

  const id = data.id;

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => {
          if (data.loanStatus === 0 && option !== "View") {
            return null; // Skip rendering this option
          }
          return (
            <MenuItem
              key={option}
              selected={option === "Pyxis"}
              onClick={handleClickMenu}
              id={id}
            >
              {option}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
