import React, { useEffect, useState, useCallback } from "react";
import {
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Autocomplete,
  TextField,
  Box,
  Typography,
  Skeleton,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PrintIcon from "@mui/icons-material/Print";
import "./JSHTable.css";

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};
function JSHTable({
  data,
  headers,
  options,
  actions,
  customButton,
  customActions,
  others,
}) {
  const [page, setPage] = useState(0);
  const [totalPageNumber, setTotalPageNumber] = useState(
    parseInt(options.totalPageNumber)
  );
  const [rowsPerPage, setRowsPerPage] = useState(options.numberPerPage || 5);
  const [users, setUsers] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (data) {
      const formattedUsers = data;
      setUsers(formattedUsers);
      setTotalPageNumber(options.totalPageNumber);
      options.page(page);
      options.setRowsPerPage(rowsPerPage);
      setTimeout(() => {
        setIsLoading(false); // Trigger loading when page changes
      }, 1000); // Adjust the delay as needed
    }
  }, [data, options, page, rowsPerPage]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedUsers(users.map((user) => user.id));
    } else {
      setSelectedUsers([]);
    }
  };

  const onSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    setIsLoading(true); // Start loading
    setUsers([]); // Clear the current users to prevent displaying old data
    handleSearchChange(value);
    setPage(0); // Reset the page to 0 when searching
  };

  // Update the debounce function to include setIsLoading(false) after the search is complete
  const handleSearchChange = useCallback(
    debounce((value) => {
      options.setSearchValue(value); // Perform your search logic here
      // Simulate an API call delay to set loading to false
      setTimeout(() => {
        setIsLoading(false); // Stop loading after search completes
      }, 300); // Adjust the delay as needed
    }, 1000), // Delay for the search input
    []
  );

  const handleSelectRow = (userId) => {
    const isSelected = selectedUsers.includes(userId);
    setSelectedUsers((prev) =>
      isSelected ? prev.filter((id) => id !== userId) : [...prev, userId]
    );
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const generateHeaderFilters = (
    <Box sx={{ display: "flex", padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Search"
            value={searchValue}
            onChange={onSearchInputChange}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={2}></Grid>
        {others}
        <Grid
          item
          xs={12}
          sm={4}
          sx={{ justifyContent: "flex-end", textAlign: "end" }}
        >
          {customButton}
        </Grid>
      </Grid>
    </Box>
  );

  const generateHeader = (
    <TableRow sx={{ background: "#d0f5d0" }}>
      {options.selectable && (
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={
              selectedUsers.length > 0 && selectedUsers.length < users.length
            }
            checked={users.length > 0 && selectedUsers.length === users.length}
            onChange={handleSelectAll}
          />
        </TableCell>
      )}
      {headers.map((header) => (
        <TableCell
          key={header.id}
          //onClick={() => (header.sortable ? handleSort(header.id) : null)}
          style={{
            cursor: header.sortable ? "pointer" : "default",
            fontWeight: "bold",
          }}
        >
          {header.label}
          {/*header.sortable && orderBy === header.id ? (
            order === "asc" ? (
              <ArrowUpwardIcon />
            ) : (
              <ArrowDownwardIcon />
            )
          ) : null*/}
        </TableCell>
      ))}
    </TableRow>
  );

  const generateTableTitle = (
    <Box
      sx={{
        display: "flex",
        padding: 2,
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h6" fontWeight={700}>
        {options.tableTitle}
      </Typography>
    </Box>
  );

  const generateRows = users.map((user) => (
    <TableRow
      key={user.id}
      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#f5f5f5")}
      onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "white")}
    >
      {options.selectable && (
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectedUsers.includes(user.id)}
            onChange={() => handleSelectRow(user.id)}
          />
        </TableCell>
      )}
      {headers.map((header) => (
        <TableCell key={header.id}>
          {header.id === "actions" ? (
            actions ? (
              <Box sx={{ display: "flex" }}>
                {actions.handleEdit ? (
                  <IconButton
                    onClick={() => actions.handleEdit(user.id)}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                ) : null}
                {actions.handlePrint ? (
                  <IconButton
                    onClick={() => actions.handlePrint(user.id)}
                    color="primary"
                  >
                    <PrintIcon />
                  </IconButton>
                ) : null}
                {actions.handleView ? (
                  <IconButton
                    onClick={() => actions.handleView(user.id)}
                    color="primary"
                  >
                    <VisibilityIcon />
                  </IconButton>
                ) : null}
                {actions.handleDelete ? (
                  <IconButton
                    onClick={() => actions.handleDelete(user.id)}
                    color="secondary"
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  actions(user.borrower_id, user, user.computed_data)
                )}
              </Box>
            ) : (
              "test"
            )
          ) : header.type === "image" ? (
            <img
              src={user[header.id]}
              alt={user[header.id]}
              style={{ width: "50px", height: "50px" }}
            />
          ) : header.type === "date" ? (
            new Date(user[header.id]).toLocaleDateString()
          ) : (
            user[header.id]
          )}
        </TableCell>
      ))}
    </TableRow>
  ));

  let rowSkeleton = (
    <>
      {Array.from({ length: rowsPerPage }).map((_, i) => (
        <TableRow key={i}>
          {headers.map((header) => (
            <TableCell key={header.id}>
              <Skeleton variant="text" animation="wave" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );

  return (
    <TableContainer component={Paper}>
      {generateHeaderFilters}

      <Table aria-label="selectable table" className="DISTable">
        <TableHead>{generateHeader}</TableHead>
        <TableBody>{isLoading ? rowSkeleton : generateRows}</TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={headers.length + 1}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalPageNumber}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => {
                  setIsLoading(true); // Trigger skeleton on page change
                  setPage(newPage);
                }}
                onRowsPerPageChange={(event) => {
                  setIsLoading(true); // Trigger skeleton on rows per page change
                  setRowsPerPage(parseInt(event.target.value, 10));
                }}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default JSHTable;
