import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import AddLoan from "../../features/loans/AddLoan";
import { Signature } from "../signature/Signature";
import Camera from "../camera/Camera";
import AddPayment from "../../features/loans/AddPayment";
import { Typography } from "@mui/material";
import ReloanData from "../../features/members/reloanForm/ReloanData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  minHeight: 500,
  //overflowY: "auto", // Enable vertical scroll if content overflows
  borderRadius: "10px",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  display: "flow-root",
  maxHeight: "95vh",
  "@media (max-width: 600px)": {
    width: "90%",
    overflowY: "auto",
  },
};
export const ModalData = ({
  closeModal,
  openModal,
  title,
  type,
  profileName,
  cameraImg,
  signatureImg,
  bodyContent,
}) => {
  let content = "";
  switch (type) {
    case "cnewLoan":
      content = <AddLoan loan_user_id={profileName} onClose={closeModal} />;
      break;
    case "ccoMaker":
    case "cborrower":
      content = (
        <Signature onClose={closeModal} onConfirm={signatureImg} type={type} />
      );
      break;
    case "ccamera":
    case "ccameraComaker":
      console.log(type);
      content = (
        <Camera
          onClose={closeModal}
          loanId={profileName}
          onConfirm={cameraImg}
        />
      );
      break;
    case "addOperator":
      content = bodyContent;
      break;
    default:
      break;
  }
  return (
    <div>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalData"
      >
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
            left: 0,
            overflow: "auto",
          }}
        >
          <Typography
            id="modal-modal-title"
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "700",
              marginBottom: "20px",
            }}
            variant="h6"
            component="h2"
          >
            {title}
          </Typography>
          <Box sx={{ marginBottom: "20px" }} />
          {content}
        </Box>
      </Modal>
    </div>
  );
};
