import React, { useCallback, useEffect } from "react";
import {
  Box,
  Backdrop,
  CircularProgress,
  Paper,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Tables from "../../components/tables/Table.js";
import DottedButton from "../../components/buttons/DottedButtons.js";
import LiveDateTime from "../../components/widgets/LiveDateTime.js";
import { useNavigate } from "react-router-dom";
import { useDashboardData } from "../../hooks/useDashboardData.js";
import "./dashboard.css";
import ResponsiveDialog from "../../components/modals/Dialog.js";
import SharedDialog from "../../components/modals/SharedDialog.js";
import dayjs from "dayjs";
import { useApproveLoanMutation } from "../../app/api/loans/loanApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentUserId } from "../../app/api/auth/authSlice.js";
import socketService from "../../app/api/ws/initializeWebSocketService.js";
import useAuth from "../../hooks/useAuth.js";
import ResponsiveList from "../../components/list/ResponsiveList.js";
import { CustomSnackbar } from "../../components/popover/Snackbar.js";
import { MemberModal } from "../../components/modals/MemberModals.js";
import JSHTable from "../../components/JSHub/JSHTable.js";

function decimalFormat(num) {
  return num.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
export default function Dashboard() {
  const [rowsPerPage, setRowPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState("");

  const {
    isLoading,
    paymentData,
    loanInfoData,
    isLoanSuccess,
    totalPage,
    applicationStatus,
  } = useDashboardData("", page, searchValue, rowsPerPage);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isDialogOpen2, setIsDialogOpen2] = React.useState(false);
  const [effectiveDate, setEffectiveDate] = React.useState("");
  const [dialogMsg, setDialogMsg] = React.useState("");
  const [dialogChoice, setDialogChoice] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [loanId, setLoanId] = React.useState("");
  const [approveLoan] = useApproveLoanMutation();
  const user_id = useSelector(selectCurrentUserId);
  const { status: userStatus } = useAuth();
  const currentDate = dayjs().format("MMM DD, YYYY");
  const [subAppData, setSubAppData] = React.useState([]);
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [profileName, setProfileName] = React.useState(null);
  const [modalTitle, setmodalTitle] = React.useState(null);
  const [modalType, setmodalType] = React.useState(null);
  const [reqDataValue, setmodalValue] = React.useState(null);
  const [bodyContent, setBodyContent] = React.useState(null);

  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [snackBarColor, setSnackBarColor] = React.useState("");

  const handleCloseSnackbar = () => {
    setPopoverOpen(false);
  };
  const handleDialogClose = () => {
    setIsDialogOpen2(false);
  };

  const handleDialogYes = useCallback(async () => {
    try {
      const statusData = {
        status: status === 1 ? 2 : 1,
        added_by: user_id,
        loan_id: loanId,
        effectiveDate: effectiveDate,
      };
      const result = await approveLoan(statusData).unwrap();
      if (result) {
        socketService.sendMessage({ type: "refresh" });
        if (status === 1) {
          setSnackBarMsg("Loan successfully approved");
          setSnackBarColor("success");
          setPopoverOpen(true);
        } else {
          setSnackBarMsg("Loan successfully rejected");
          setSnackBarColor("error");
          setPopoverOpen(true);
        }
        setIsDialogOpen2(false);
      }
    } catch (err) {
      console.log(err);
    }
  }, [status, user_id, loanId, effectiveDate, approveLoan]);

  useEffect(() => {
    if (dialogChoice === 1) {
      if (status === 0) {
        handleDialogYes();
      } else {
        setIsDialogOpen2(true);
      }
      setDialogChoice("");
    }
  }, [dialogChoice, status, handleDialogYes]);

  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/members/add");
  };
  //Dashboard table data
  let options = {};
  options = {
    allowEdit: true,
    allowDelete: true,
    allowView: true,
    allowAdd: true,
    allowImport: true,
    allowExport: true,
    allowSearch: true,
    numberPerPage: 10,
    selectable: false,
    page: setPage,
    totalPageNumber: 0,
    //tableTitle: "Employee List",
    isLoading: isLoading,
    setSearchValue: setSearchValue,
    searchValue: searchValue,
    setRowsPerPage: setRowPerPage,
  };

  options.totalPageNumber = totalPage;

  const title = "";

  let columns = [];
  columns = [
    /*{
      id: "id",
      label: "ID",
      sortable: true,
    },
    {
      id: "borrower_id",
      label: "Borrower ID",
      sortable: true,
    },*/
    {
      id: "name",
      label: "Name",
      sortable: true,
    },
    {
      id: "effective_date",
      label: "Effective Date",
      sortable: true,
    },

    {
      id: "loan_amount",
      label: "Principal Amount",
      sortable: true,
    },
    {
      id: "next_due_date",
      label: "Next Due Date",
      sortable: true,
    },
    {
      id: "amount_to_be_paid",
      label: "Amount to Pay",
      sortable: true,
    },
    {
      id: "loan_type",
      label: "Loan Type",
      sortable: true,
    },
    {
      id: "loan_terms",
      label: "Paid Terms",
      sortable: true,
    },
    {
      id: "remaining_balance",
      label: "Remaining Balance",
      sortable: true,
    },
    {
      id: "status",
      label: "Status",
      sortable: true,
    },

    {
      id: "actions",
      label: "",
      sortable: false,
    },
  ];

  const sortColumn = {
    name: "Status",
    direction: "asc", // or 'desc'
  };
  let countToday = 0;
  const tableData = loanInfoData.map((item) => {
    if (
      dayjs(item.computed.due_date).format("DD-MM-YYYY") ===
      dayjs(currentDate).format("DD-MM-YYYY")
    ) {
      countToday++;
    }
    return {
      id: item.computed.loanId,
      borrower_id: item.borrower_id, // Include borrower_id in the data
      effective_date: dayjs(item.effectived_date).format("MMM DD, YYYY"),
      name: `${item.lastname}, ${item.firstname} ${
        item.middlename ? item.middlename.charAt(0) + "." : ""
      } `,
      loan_amount: `₱${decimalFormat(item.computed.loanAmount)}`,
      next_due_date: item.computed.due_date, // Add logic for Next Due Date if available
      amount_to_be_paid: `₱${decimalFormat(item.computed.monthlyPayment)}`,
      loan_type:
        item.loan_type === 0
          ? "Monthly"
          : item.loan_type === 1
          ? "Flexible"
          : "10th - 25th",
      loan_terms:
        item.loan_type === 0
          ? `${item.computed.paidTerms} out of ${item.loan_terms}`
          : `${item.computed.paidTerms} of ${item.loan_terms} Months`,
      remaining_balance: `₱${decimalFormat(item.computed.remaining_balance)}`,
      computed: item.computed,
      status:
        item.status === 0
          ? "Pending"
          : item.status === 1
          ? "Rejected"
          : item.status === 2
          ? "Approved"
          : "Fully Paid",
    };
  });

  useEffect(() => {
    if (isLoanSuccess) {
      let barChartData = [];

      barChartData = [
        {
          title: "Pending",
          value: applicationStatus.pending,
        },
        {
          title: "Approved",
          value: applicationStatus.approved,
        },
        {
          title: "Reject",
          value: applicationStatus.rejected,
        },
        {
          title: "Closed",
          value: applicationStatus.closed,
        },
        {
          title: "Total",
          value: applicationStatus.total,
        },
      ];
      setSubAppData(barChartData);
    }
  }, [isLoanSuccess, applicationStatus]);

  const categories = [
    {
      id: 1,
      title: "Expected Collection",
      value: countToday,
      bgColor: "#973131",
    },
    /*{
      id: 2,
      title: "Collected",
      value: paymentData.widgetCount,
      bgColor: "#FDA403",
    },*/
    {
      id: 2,
      title: "Payment Received",
      value: `₱${decimalFormat(parseFloat(paymentData.payment))}`,
      fsize: "30px",
      bgColor: "#87A922",
    },
    {
      id: 3,
      title: "Withdrawn",
      value: `₱${decimalFormat(parseFloat(paymentData.withdrawnAmount))}`,
      bgColor: "#D4A3A3",
      fsize: "30px",
    },
    {
      id: 4,
      title: "Newly Applied",
      value: paymentData.newlyApplied,
      bgColor: "#1679AB",
    },
    {
      id: 5,
      title: "Application",
      value: 7,
      bgColor: "#32012F",
      subTitle: subAppData,
    },
  ];

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const btnOptions = ["View"];
  const customBtn = (id, data, computed_data) => {
    let updatedBtnOptions = [...btnOptions];

    if (data.status === "Pending") {
      if (userStatus === "Admin") {
        updatedBtnOptions.push("Edit Loan", "Approve", "Reject");
      } else {
        updatedBtnOptions.push("Edit Loan");
      }
    } else if (data.status === "Approved") {
      if (data.remaining_balance !== "₱0.00") {
        updatedBtnOptions.push("Less Payment", "Reloan");
      }

      //if (data.loan_type === "Flexible") {
      updatedBtnOptions.push("Withdraw");
      //}
    }
    return (
      <DottedButton
        options={updatedBtnOptions}
        id={id}
        data={data}
        borrower_id={data.borrower_id} // Adding borrower_id here
        uid={data.borrower_id}
        loanId={data.id}
        loanStatus={data.loan_status}
        setUserId={setLoanId}
        type={"loans"}
        isDialogOpen={setIsDialogOpen}
        dialogMsg={setDialogMsg}
        status={setStatus}
        effectiveDate={dayjs(data.effective_date)}
        setEffectiveDate={setEffectiveDate}
        setPopoverOpen={setPopoverOpen}
        setModalOpen={setModalOpen}
        setProfileName={setProfileName}
        setmodalTitle={setmodalTitle}
        setmodalType={setmodalType}
        setmodalValue={setmodalValue}
        setBodyContent={setBodyContent}
      />
    );
  };

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <h2 className="dateFormatting">
        <LiveDateTime />
      </h2>

      <Box height={20} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <ResponsiveList categories={categories} />
          </Stack>
        </Grid>
        <Box height={30} />
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={handleButtonClick}
              startIcon={<AddCircleIcon />}
            >
              New Application
            </Button>
          </Grid>
          <Box height={10} />
          <JSHTable
            headers={columns}
            data={tableData}
            //title={title}
            options={options}
            actions={customBtn}
          />
        </Grid>
      </Grid>
      {modalOpen && (
        <MemberModal
          closeModal={handleCloseModal}
          onSubmit={handleCloseModal}
          onCancel={handleCloseModal}
          openModal={modalOpen}
          title={modalTitle}
          type={modalType}
          reqValue={reqDataValue}
          profileName={profileName}
          BodyContent={bodyContent}
          setSnackBarMsg={setSnackBarMsg}
          setSnackBarColor={setSnackBarColor}
          setPopoverOpen={setPopoverOpen}
        />
      )}
      {isDialogOpen && (
        <SharedDialog
          isDialogOpen={setIsDialogOpen}
          isOpen={isDialogOpen}
          title={"Borrower"}
          bodyContent={dialogMsg}
          setDialogChoice={setDialogChoice}
        />
      )}
      {isDialogOpen2 && (
        <ResponsiveDialog
          dialogOpen={isDialogOpen2}
          handleDialogYes={handleDialogYes}
          handleDialogClose={handleDialogClose}
          setEffectiveDate={setEffectiveDate}
          effectiveDate={effectiveDate}
          dialogType={status}
        />
      )}
      {popoverOpen && (
        <CustomSnackbar
          openSnackBar={popoverOpen}
          closeSnackBar={handleCloseSnackbar}
          messageData={snackBarMsg}
          colorVariant={snackBarColor}
        />
      )}
    </Paper>
  );
}
