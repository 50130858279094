import { useEffect, useState } from "react";
import { useGetDashboardDataQuery } from "../app/api/users/usersApiSlice";
import { useGetAllLoansQuery } from "../app/api/loans/loanApiSlice";
import computationMonthly from "./useComputation";
import computeFlexible from "./useComputeFlexible";
import computer1025th from "./useCompute1025th";
import dayjs from "dayjs";

export function useDashboardData(
  id = "",
  page = 0,
  searchValue = "",
  rowsPerPage = 5
) {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [memberGroupData, setMemberGroupData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [loanData, setLoanData] = useState([]);
  const [loanPaymentData, setLoanPaymentData] = useState([]);
  const [loanInfoData, setLoanInfoData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [applicationStatus, setApplicationStatus] = useState([]);

  const {
    data: users,
    isLoading: isUsersLoading,
    isSuccess: isUsersSuccess,
  } = useGetDashboardDataQuery(id, {
    pollingInterval: 3600000,
    refetchOnWindowFocus: true, // Refetch when window regains focus
    refetchOnMountOrArgChange: true,
  }); // 1 hour in milliseconds
  const {
    data: loans,
    isLoading,
    isSuccess: isLoanSuccess,
  } = useGetAllLoansQuery(
    { id, page: page + 1, search: searchValue, rowsPerPage },
    {
      pollingInterval: 3600000,
      refetchOnWindowFocus: true, // Refetch when window regains focus
      refetchOnMountOrArgChange: true,
    }
  ); // 1 hour in milliseconds

  useEffect(() => {
    if (users && !isUsersLoading) {
      processUsersData(users);
    }
  }, [users, isUsersLoading]);

  useEffect(() => {
    if (loans && !isLoading) {
      processLoansData(loans);
    }
  }, [loans, isLoading]);

  const processUsersData = (users) => {
    const paymentData = {
      payment: [],
      widgetCount: [],
      withdrawnAmount: [],
      newlyApplied: [],
    };
    const applicationStatus = {
      pending: 0,
      approved: 0,
      rejected: 0,
      closed: 0,
      total: 0,
    };

    paymentData.payment = users[0].totalPaymentToday;
    paymentData.withdrawnAmount = users[0].totalWithdrawToday;
    paymentData.newlyApplied = users[0].totalNewlyAppliedLoans;

    applicationStatus.pending = users[0].totalLoansStatus0;
    applicationStatus.approved = users[0].totalLoansStatus2;
    applicationStatus.rejected = users[0].totalLoansStatus1;
    applicationStatus.closed = users[0].totalLoansStatus3;
    applicationStatus.total = users[0].totalLoanCount;

    setPaymentData(paymentData);
    setApplicationStatus(applicationStatus);
  };

  const processLoansData = (loans) => {
    let loanData = {};
    let loanInfo = [];
    let computationData = {};

    loans[0].data.forEach((loan) => {
      // Group loans by status if not already grouped
      if (!loanData[loan.status]) {
        loanData[loan.status] = {};
      }

      // Store the loan data by loan ID
      loanData[loan.status][loan.id] = loan;

      // Prepare computation data
      computationData = {
        amount: loan.loan_amount,
        interest: parseFloat(loan.loan_interest),
        term: loan.loan_terms,
        loanId: loan.id,
        payment: loan.payments,
        effectived_date: loan.effectived_date,
        loan_type: loan.loan_type,
        loan_status: loan.status,
        applied_date: loan.applied_date,
      };

      // Compute based on loan type
      let computationResult = [];
      if (loan.loan_type === 0) {
        computationResult = computationMonthly(computationData);
      } else if (loan.loan_type === 1) {
        computationResult = computeFlexible(computationData);
      } else if (loan.loan_type === 2) {
        computationResult = computer1025th(computationData);
      }

      // Store detailed loan info by loan ID
      /*loanInfo[loan.id] = {
        comaker_firstname: loan.comaker_firstname,
        comaker_lastname: loan.comaker_lastname,
        comaker_middlename: loan.comaker_middlename,
        firstname: loan.firstname,
        lastname: loan.lastname,
        middlename: loan.middlename,
        loan_amount: loan.loan_amount,
        loan_interest: parseFloat(loan.loan_interest),
        applied_date: loan.applied_date,
        effectived_date: loan.effectived_date,
        status: loan.status,
        loan_type: loan.loan_type,
        loan_terms: loan.loan_terms,
        borrower_id: loan.borrower_id,
        computed: computationResult,
      };*/
      loanInfo.push({
        comaker_firstname: loan.comaker_firstname,
        comaker_lastname: loan.comaker_lastname,
        comaker_middlename: loan.comaker_middlename,
        firstname: loan.firstname,
        lastname: loan.lastname,
        middlename: loan.middlename,
        loan_amount: loan.loan_amount,
        loan_interest: parseFloat(loan.loan_interest),
        applied_date: loan.applied_date,
        effectived_date: loan.effectived_date,
        status: loan.status,
        loan_type: loan.loan_type,
        loan_terms: loan.loan_terms,
        borrower_id: loan.borrower_id,
        computed: computationResult,
      });
    });

    // Set state with processed data
    setLoanInfoData(loanInfo);
    setLoanData(loanData);
    if (loans[0].metadata[0] !== undefined) {
      setTotalPage(loans[0].metadata[0].total);
    }
  };

  return {
    data,
    allData,
    //isLoading: isUsersLoading,
    isLoading,
    isSuccess: isUsersSuccess,
    isLoanSuccess,
    paymentData,
    memberGroupData,
    loanData,
    loanPaymentData,
    loanInfoData,
    totalPage,
    applicationStatus,
  };
}
