import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

export const borrowersAdapter = createEntityAdapter();

export const initialState = borrowersAdapter.getInitialState();

const borrowersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBorrowersList: builder.query({
      query: ({ page, rowsPerPage, search }) => {
        const params = new URLSearchParams();

        if (page) params.append("page", page);
        if (search) params.append("search", search);
        if (rowsPerPage) params.append("rowsPerPage", rowsPerPage);

        return {
          url: `/borrowers?${params.toString()}`,
          method: "GET",
        };
      },
      providesTags: (result) =>
        result
          ? [
              { type: "Borrowers", id: "LIST" },
              { type: "Borrowers", id: "LIST", page: result.page },
            ]
          : [{ type: "Borrowers", id: "LIST" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetBorrowersListQuery } = borrowersSlice;
