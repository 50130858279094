import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

const postsAdapter = createEntityAdapter();

const initialState = postsAdapter.getInitialState();

const analyticsReportSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAnalyticsReport: builder.query({
      query: () => "/analytics",
      providesTags: ["Reports"],
    }),
    getLoanAnalytics: builder.query({
      query: () => "/analytics/loanAnalytics",
      providesTags: ["Reports"],
    }),
    getMemberAnalytics: builder.query({
      query: () => "/analytics/memberAnalytics",
      providesTags: ["Reports"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAnalyticsReportQuery,
  useGetLoanAnalyticsQuery,
  useGetMemberAnalyticsQuery,
} = analyticsReportSlice;
