import React, { useEffect, useState } from "react";
import { Box, Backdrop, CircularProgress, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import BarChartWidget from "../../components/graph/BarChart.js";
import PieChartWidget from "../../components/graph/PieChart.js";
import LiveDateTime from "../../components/widgets/LiveDateTime.js";
import {
  useGetAnalyticsReportQuery,
  useGetLoanAnalyticsQuery,
  useGetMemberAnalyticsQuery,
} from "../../app/api/reports/analyticsReport.js";
import LineChartWidget from "../../components/graph/LineChart.js";
import dayjs from "dayjs";

export default function AdminDashboard() {
  const [pieData, setPieData] = useState([]);
  const [barData, setBarData] = useState([]);

  //const { data: analyticsReportData } = useGetAnalyticsReportQuery();
  const { data: loanAnalyticsData, isLoading: isLoanLoading } =
    useGetLoanAnalyticsQuery({
      refetchOnMountOrArgChange: true,
    });
  const { data: memberGroupData, isLoading: isMemberLoading } =
    useGetMemberAnalyticsQuery({
      refetchOnMountOrArgChange: true,
    });

  useEffect(() => {
    if (!isMemberLoading) {
      let pieChartData = [];
      memberGroupData.forEach((group) => {
        pieChartData.push({
          label: group._id,
          value: group.count,
        });
      });
      setPieData(pieChartData);
    }
  }, [isMemberLoading, memberGroupData]);

  useEffect(() => {
    if (loanAnalyticsData) {
      let barChartData = [];
      let pending = 0;
      let approved = 0;
      let rejected = 0;
      let closed = 0;
      loanAnalyticsData.forEach((loan) => {
        if (loan._id === 0) {
          pending = loan.count;
        } else if (loan._id === 1) {
          rejected = loan.count;
        } else if (loan._id === 2) {
          approved = loan.count;
        } else if (loan._id === 3) {
          closed = loan.count;
        }
      });
      barChartData.push({
        pending,
        rejected,
        approved,
        closed,
        month: "Loan Status",
      });
      setBarData(barChartData);
    }
  }, [loanAnalyticsData]);

  /*const payments = loanPaymentData?.payment ?? [];
  const monthDate = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const currentYear = dayjs().year();

  const groupedData = payments.reduce((acc, payment) => {
    const paymentMonth = dayjs(payment.payment_date).format("YYYY-MM");
    if (!acc[paymentMonth]) {
      acc[paymentMonth] = 0;
    }
    if (payment.status === 0) {
      acc[paymentMonth] += payment.amount;
    }

    return acc;
  }, {});

  // Fill in missing months with 0 for the current year only
  monthDate.forEach((month) => {
    const formattedMonth = `${currentYear}-${month
      .toString()
      .padStart(2, "0")}`;
    if (!groupedData[formattedMonth]) {
      groupedData[formattedMonth] = 0;
    }
  });

  const sortedEntries = Object.entries(groupedData)
    .filter(([month]) => month.startsWith(currentYear.toString()))
    .sort((a, b) => a[0].localeCompare(b[0]));

  const udata = sortedEntries.map((entry) => entry[1]);

  console.log(loanInfoData);
  const [pdata, setPdata] = useState({});
  useEffect(() => {
    if (isLoanSuccess) {
      const loanInfo = loanInfoData ?? [];
      const pdata = {}; // Initialize pdata to store monthly payments
      loanInfo.forEach((loan) => {
        if (loan.status === 2) {
          const terms = parseInt(loan.loan_terms);
          const loan_type = parseInt(loan.loan_type);
          const startDate = dayjs(loan.effectived_date); // Use dayjs for consistency
          monthDate.forEach((month, index) => {
            const currentMonth = startDate.add(index, "month").format("MM");
            const currentYearMonth = startDate
              .add(index, "month")
              .format("YYYY-MM");
            const year = parseInt(currentYearMonth.split("-")[0], 10);
            const monthNum = parseInt(currentMonth, 10);

            // Ensure only months from 1 to 12 of the current year are populated
            if (year === currentYear && monthNum >= 1 && monthNum <= 12) {
              if (!pdata[currentYearMonth]) {
                pdata[currentYearMonth] = 0;
              }
              if (
                (loan_type === 1 && index < 1) ||
                (loan_type !== 1 && index < terms)
              ) {
                pdata[currentYearMonth] += loan.computed.monthlyPayment;
              }
            }
          });
        }
      });

      // Ensure all months in the year have entries, set to 0 if missing
      monthDate.forEach((month) => {
        const formattedMonth = `${currentYear}-${month
          .toString()
          .padStart(2, "0")}`;
        if (!pdata[formattedMonth]) {
          pdata[formattedMonth] = 0;
        }
      });

      const sortedEntries = Object.entries(pdata)
        .filter(([month]) => month.startsWith(currentYear.toString()))
        .sort((a, b) => a[0].localeCompare(b[0]));

      const pfdata = sortedEntries.map((entry) => entry[1]);
      setPdata(pfdata);
    }
  }, [isLoanSuccess, loanInfoData]);

  console.log(udata);
  console.log(pdata);*/

  return (
    <Paper elevation={3} sx={{ p: 2, overflowX: "auto" }}>
      <h2 className="dateFormatting">
        <LiveDateTime />
      </h2>
      <Box height={20} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <BarChartWidget barChartData={barData} isLoading={isLoanLoading} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <PieChartWidget
              pieChartData={pieData}
              isLoading={isMemberLoading}
            />
          </Paper>
        </Grid>
        {/*<Grid item xs={12} sm={12} md={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <LineChartWidget
              udata={udata}
              pdata={pdata}
              isLoading={isUsersLoading}
            />
          </Paper>
        </Grid>*/}
      </Grid>
    </Paper>
  );
}
/**/
