const computationMonthly = (data) => {
  let amount = parseFloat(data.amount);
  let loan_interest = parseFloat(data.interest);
  let term = parseFloat(data.term);
  let loanAmount = (amount / 100) * loan_interest;
  let loanTerm = loanAmount * term;
  let finalAmount = amount + loanTerm;
  let originalAmount = 0;
  let remaining_balance = 0;
  originalAmount = parseFloat(data.amount);
  let monthlyPayment = finalAmount / term;
  if (data.loan_type === 1) {
    monthlyPayment = finalAmount - amount;
  }

  let today;
  let total_paid_terms = 0; // Initialize the sum
  let total_paid_amount = 0;
  let last_paid_amount = 0;
  let withdrawAmount = 0;
  if (Array.isArray(data.payment)) {
    data.payment.forEach((payment, index) => {
      if (payment.status === 1) {
        withdrawAmount += parseFloat(payment.amount);
      } else {
        total_paid_terms += parseFloat(payment.num_term);
        total_paid_amount += parseFloat(payment.amount);
      }

      if (index === data.payment.length - 1) {
        last_paid_amount = parseFloat(payment.amount);
      }
    });
  } else {
    // Handle the case where data.payment is not an array or is undefined
    console.error("data.payment is not defined or not an array.");
    // Optionally, initialize your variables to avoid reference errors
    total_paid_terms = 0;
    total_paid_amount = 0;
    last_paid_amount = 0;
  }

  if (
    data.effectived_date === null ||
    typeof data.effectived_date === "undefined"
  ) {
    today = new Date();
  } else {
    today = new Date(data.effectived_date);
  }

  remaining_balance = finalAmount - (total_paid_amount - withdrawAmount);

  let next_due_date = "";
  if (total_paid_terms === 0) {
    const date = new Date(today);
    date.setMonth(today.getMonth());
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    next_due_date = `${month} ${day},  ${year}`;
  } else {
    const date = new Date(today);
    date.setMonth(today.getMonth() + total_paid_terms);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    next_due_date = `${month} ${day},  ${year}`;
  }

  return {
    effectived_date: data.effectived_date,
    loanId: data.loanId,
    paidTerms: total_paid_terms,
    paidAmount: total_paid_amount,
    loanAmount: amount,
    loanTerm: term,
    loanInterest: parseFloat(loan_interest),
    finalAmount: finalAmount,
    due_date: next_due_date,
    monthlyPayment: monthlyPayment,
    payments: data.payment,
    loanType: data.loan_type,
    lastPaidAmount: last_paid_amount,
    loan_status: data.loan_status,
    remaining_balance: remaining_balance,
    originalAmount: originalAmount,
  };
};

export default computationMonthly;
