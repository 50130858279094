import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

const postsAdapter = createEntityAdapter();

const initialState = postsAdapter.getInitialState();

export const loanApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addLoan: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/loans",
          method: "POST",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [
        { type: "Users", id: "Members" },
        { type: "Users", id: "Payments" },
        { type: "Loans", id: "Loans" },
      ],
    }),
    addPayment: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/loans/payment",
          method: "POST",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [
        { type: "Users", id: "Members" },
        { type: "Users", id: "Payments" },
        { type: "Loans", id: "Loans" },
      ],
    }),
    updateLoan: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/loans/update",
          method: "PUT",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [
        { type: "Users", id: "Members" },
        { type: "Users", id: "Payments" },
        { type: "Loans", id: "Loans" },
      ],
    }),
    approveLoan: builder.mutation({
      query: (initialPost) => ({
        url: `/loans/approve`,
        method: "POST",
        body: {
          ...initialPost,
          date: new Date().toISOString(),
        },
      }),
      invalidatesTags: [
        { type: "Users", id: "Members" },
        { type: "Users", id: "Payments" },
        { type: "Loans", id: "Loans" },
      ],
    }),
    getAllLoans: builder.query({
      query: ({ id, page, search, rowsPerPage }) =>
        `/loans?id=${id}&page=${page}&q=${search}&rowsPerPage=${rowsPerPage}`,
      providesTags: [{ type: "Loans", id: "Loans" }],
    }),
  }),
});

export const {
  useAddLoanMutation,
  useAddPaymentMutation,
  useApproveLoanMutation,
  useGetAllLoansQuery,
  useUpdateLoanMutation,
} = loanApiSlice;
